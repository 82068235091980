import React, { useState, useEffect, useRef } from "react";
import {
  Shield,
  Heart,
  CloudSun,
  DollarSign,
  Scale,
  Briefcase,
  Cpu,
  Globe,
  School,
  Flag,
  Users,
  Cross,
  FileCheck,
  Book,
  Zap,
  Smartphone,
  Home,
  TrendingUp,
  Droplet,
  Battery,
  Medal,
  GraduationCap,
  UserPlus,
  Search,
} from "lucide-react";

/*

  // Prompting Blob

  const candidates = {
    "Candidate Name": {
      _color: "{blue, red, purple}",
      "Issue Name": {
        text: "Issue Description",
        value: -100, // -100 to 100 (negative is the candidate leaning more progressive on the issue, positive is more conservative)
      },
    }
  }

  Current Candidates:
    Kamala Harris
    Kamala Harris
    Donald Trump
    J.D. Vance
    Robert F. Kennedy Jr.
    Project 2025
    GOP Platform

  Current Issues:
    Gun Control
    Healthcare
    Income Inequality
    Student Debt Relief
    Criminal Justice Reform
    Climate Change
    Taxes
    Immigration
    Voting Rights
    Economy & Jobs
    AI Regulation
    Foreign Policy
    Education
    Racial Equality
    Affirmative Action
    Ukraine Conflict
    China-Taiwan Relations
    Russia Relations
    China Policy
    Israel-Palestine Conflict
    LGBTQ+ Rights
    Religious Freedom
    Women's Rights
    Election Integrity
    Critical Race Theory
    Energy Policy
    Tech Regulation
    Homelessness
    Stock Market
    Gas Prices
    Energy Independence
    Veteran Affairs
    Use of Force View
    Public Schools

  """
  
  Given the provided list of Candidates and Issues, please add the following issue(s) to the candidates object for each candidate. 
  Please ensure you act in an unbaised and fair manner when summarizing candidate's stance on the issue(s), and providing an estimation of their "value" on the issue(s).

  Issue: "Issue Name"
  Issue: "Issue Name"
  Issue: "Issue Name"

  Respond with only the new issues added to the candidates object, and the corresponding values for each candidate.

  """
*/

const issues = [
  {
    name: "Gun Control",
    icon: Shield,
  },
  {
    name: "Healthcare",
    icon: Heart,
  },
  {
    name: "Income Inequality",
    icon: DollarSign,
  },
  {
    name: "Student Debt Relief",
    icon: GraduationCap,
  },
  {
    name: "Criminal Justice Reform",
    icon: UserPlus,
  },
  {
    name: "Climate Change",
    icon: CloudSun,
  },
  {
    name: "Taxes",
    icon: DollarSign,
  },
  {
    name: "Immigration",
    icon: Users,
  },
  {
    name: "Voting Rights",
    icon: Scale,
  },
  {
    name: "Economy & Jobs",
    icon: Briefcase,
  },
  {
    name: "AI Regulation",
    icon: Cpu,
  },
  {
    name: "Foreign Policy",
    icon: Globe,
  },
  {
    name: "Education",
    icon: School,
  },
  {
    name: "Racial Equality",
    icon: Users,
  },
  {
    name: "Affirmative Action",
    icon: Scale,
  },
  {
    name: "Ukraine Conflict",
    icon: Flag,
  },
  {
    name: "China-Taiwan Relations",
    icon: Flag,
  },
  {
    name: "Russia Relations",
    icon: Flag,
  },
  {
    name: "China Policy",
    icon: Flag,
  },
  {
    name: "Israel-Palestine Conflict",
    icon: Flag,
  },
  {
    name: "LGBTQ+ Rights",
    icon: Users,
  },
  {
    name: "Religious Freedom",
    icon: Cross,
  },
  {
    name: "Women's Rights",
    icon: Users,
  },
  {
    name: "Election Integrity",
    icon: FileCheck,
  },
  {
    name: "Critical Race Theory",
    icon: Book,
  },
  {
    name: "Energy Policy",
    icon: Zap,
  },
  {
    name: "Tech Regulation",
    icon: Smartphone,
  },
  {
    name: "Homelessness",
    icon: Home,
  },
  {
    name: "Stock Market",
    icon: TrendingUp,
  },
  {
    name: "Gas Prices",
    icon: Droplet,
  },
  {
    name: "Energy Independence",
    icon: Battery,
  },
  {
    name: "Veteran Affairs",
    icon: Medal,
  },
  {
    name: "Use of Force View",
    icon: Shield,
  },
  {
    name: "Public Schools",
    icon: School,
  },
];

const candidates = {
  "Kamala Harris": {
    _color: "blue",
    "Gun Control": {
      text: "Supports stricter gun control measures, including assault weapons ban and universal background checks",
      value: -80,
    },
    Healthcare: {
      text: "Supports Medicare for All with a role for private insurance",
      value: -70,
    },
    "Income Inequality": {
      text: "Advocates for higher taxes on wealthy, expanded social programs",
      value: -70,
    },
    "Student Debt Relief": {
      text: "Supports some student debt forgiveness and free community college",
      value: -60,
    },
    "Criminal Justice Reform": {
      text: "Advocates for reform, ending cash bail, focuses on rehabilitation",
      value: -70,
    },
    "Climate Change": {
      text: "Supports Green New Deal and rejoining Paris Agreement",
      value: -80,
    },
    Taxes: {
      text: "Supports higher taxes on corporations and wealthy individuals",
      value: -70,
    },
    Immigration: {
      text: "Supports path to citizenship for undocumented immigrants, DACA protection",
      value: -70,
    },
    "Voting Rights": {
      text: "Advocates for expanding voting access, opposes strict ID laws",
      value: -80,
    },
    "Economy & Jobs": {
      text: "Supports workers' rights, raising minimum wage, and green job creation",
      value: -60,
    },
    "AI Regulation": {
      text: "Supports developing AI regulations to ensure safety, ethics, and equity",
      value: -50,
    },
    "Foreign Policy": {
      text: "Emphasizes diplomacy, rebuilding alliances, and addressing global challenges",
      value: -60,
    },
    Education: {
      text: "Supports increased federal funding for public schools and teacher pay",
      value: -70,
    },
    "Racial Equality": {
      text: "Strongly supports policies to address systemic racism and promote racial equity",
      value: -80,
    },
    "Affirmative Action": {
      text: "Supports affirmative action policies in education and employment",
      value: -70,
    },
    "Ukraine Conflict": {
      text: "Supports Ukraine with military and economic aid, advocates for international coalition against Russian aggression",
      value: -70,
    },
    "China-Taiwan Relations": {
      text: "Supports Taiwan while maintaining strategic ambiguity, opposes unilateral changes to status quo",
      value: -60,
    },
    "Russia Relations": {
      text: "Takes a confrontational stance towards Russia, supports sanctions over Ukraine invasion",
      value: -70,
    },
    "China Policy": {
      text: "Supports a competitive approach to China, focusing on strengthening alliances in Asia",
      value: -60,
    },
    "Israel-Palestine Conflict": {
      text: "Supports two-state solution, maintains strong support for Israel while advocating for Palestinian rights",
      value: -50,
    },
    "LGBTQ+ Rights": {
      text: "Strong supporter of LGBTQ+ rights, including Equality Act",
      value: -90,
    },
    "Religious Freedom": {
      text: "Supports religious freedom balanced with civil rights protections",
      value: -60,
    },
    "Women's Rights": {
      text: "Advocates for women's reproductive rights, pay equity, and combating gender-based violence",
      value: -80,
    },
    "Election Integrity": {
      text: "Supports expanding voting access, opposes strict voter ID laws",
      value: -70,
    },
    "Critical Race Theory": {
      text: "Supports diversity and inclusion initiatives in education and government",
      value: -70,
    },
    "Energy Policy": {
      text: "Supports transition to clean energy, advocates for rejoining Paris Agreement",
      value: -80,
    },
    "Tech Regulation": {
      text: "Supports increased regulation of big tech, focus on privacy and antitrust",
      value: -60,
    },
    Homelessness: {
      text: "Supports increased funding for housing assistance and homelessness prevention programs",
      value: -70,
    },
    "Stock Market": {
      text: "Supports some increased market regulations and higher taxes on capital gains",
      value: -60,
    },
    "Gas Prices": {
      text: "Focuses on long-term clean energy transition, supports measures to alleviate short-term price spikes",
      value: -50,
    },
    "Energy Independence": {
      text: "Emphasizes clean energy transition while maintaining energy security",
      value: -60,
    },
    "Veteran Affairs": {
      text: "Supports expanded VA funding and services, focus on mental health and homelessness",
      value: -50,
    },
    "Use of Force View": {
      text: "Supports police reform and accountability, emphasizes de-escalation",
      value: -60,
    },
    "Public Schools": {
      text: "Strongly supports increased federal funding for public schools and teacher pay",
      value: -70,
    },
  },
  "Tim Walz": {
    _color: "blue",
    "Gun Control": {
      text: "Supports universal background checks and measures to prevent gun violence",
      value: -65,
    },
    Healthcare: {
      text: "Supports expanding healthcare access and lowering prescription drug costs",
      value: -55,
    },
    "Income Inequality": {
      text: "Advocates for living wages and stronger labor rights",
      value: -60,
    },
    "Student Debt Relief": {
      text: "Supports initiatives to make college more affordable and reduce student debt",
      value: -65,
    },
    "Criminal Justice Reform": {
      text: "Focuses on reducing recidivism and reforming the justice system",
      value: -55,
    },
    "Climate Change": {
      text: "Supports aggressive action on climate change and renewable energy investments",
      value: -70,
    },
    Taxes: {
      text: "Supports progressive taxation and closing corporate tax loopholes",
      value: -60,
    },
    Immigration: {
      text: "Advocates for comprehensive immigration reform and protection for DREAMers",
      value: -60,
    },
    "Voting Rights": {
      text: "Supports expanding voter access and opposing voter suppression",
      value: -70,
    },
    "Economy & Jobs": {
      text: "Focuses on job creation and supporting small businesses",
      value: -55,
    },
    "AI Regulation": {
      text: "Supports developing ethical guidelines for AI use",
      value: -45,
    },
    "Foreign Policy": {
      text: "Advocates for diplomacy and international cooperation",
      value: -55,
    },
    Education: {
      text: "Supports increased funding for public education and teacher pay",
      value: -60,
    },
    "Racial Equality": {
      text: "Promotes policies to address racial disparities and systemic racism",
      value: -65,
    },
    "Affirmative Action": {
      text: "Supports affirmative action in education and employment",
      value: -65,
    },
    "Ukraine Conflict": {
      text: "Supports aid to Ukraine and sanctions on Russia",
      value: -65,
    },
    "China-Taiwan Relations": {
      text: "Supports Taiwan's self-governance and opposes unilateral changes by China",
      value: -55,
    },
    "Russia Relations": {
      text: "Supports strong stance against Russian aggression",
      value: -65,
    },
    "China Policy": {
      text: "Supports balancing competition with cooperation where possible",
      value: -50,
    },
    "Israel-Palestine Conflict": {
      text: "Supports a two-state solution and maintaining strong U.S.-Israel relations",
      value: -50,
    },
    "LGBTQ+ Rights": {
      text: "Supports LGBTQ+ rights and protections against discrimination",
      value: -75,
    },
    "Religious Freedom": {
      text: "Supports religious freedom balanced with civil rights protections",
      value: -45,
    },
    "Women's Rights": {
      text: "Advocates for women's reproductive rights and gender equality",
      value: -65,
    },
    "Election Integrity": {
      text: "Supports measures to secure elections and expand voter access",
      value: -65,
    },
    "Critical Race Theory": {
      text: "Supports education on diversity and inclusion",
      value: -55,
    },
    "Energy Policy": {
      text: "Advocates for clean energy and reducing carbon emissions",
      value: -70,
    },
    "Tech Regulation": {
      text: "Supports measures to ensure tech privacy and accountability",
      value: -45,
    },
    Homelessness: {
      text: "Focuses on increasing affordable housing and support services",
      value: -60,
    },
    "Stock Market": {
      text: "Supports regulation to prevent market abuses",
      value: -55,
    },
    "Gas Prices": {
      text: "Advocates for sustainable energy solutions to reduce dependency on oil",
      value: -45,
    },
    "Energy Independence": {
      text: "Supports clean energy initiatives to achieve energy independence",
      value: -55,
    },
    "Veteran Affairs": {
      text: "Supports increasing funding and services for veterans",
      value: -50,
    },
    "Use of Force View": {
      text: "Supports police reform and community-based policing",
      value: -45,
    },
    "Public Schools": {
      text: "Supports increased funding for public schools and teacher pay",
      value: -60,
    },
  },
  "Donald Trump": {
    _color: "red",
    "Gun Control": {
      text: "Opposes new gun control measures, supports 2nd Amendment rights",
      value: 80,
    },
    Healthcare: {
      text: "Aims to repeal ACA, supports private insurance-based system",
      value: 70,
    },
    "Income Inequality": {
      text: "Supports tax cuts, believes in trickle-down economics",
      value: 80,
    },
    "Student Debt Relief": {
      text: "Generally opposes broad student debt forgiveness",
      value: 70,
    },
    "Criminal Justice Reform": {
      text: "Signed First Step Act, but generally tough on crime",
      value: 40,
    },
    "Climate Change": {
      text: "Skeptical of climate change, supports fossil fuel industry",
      value: 80,
    },
    Taxes: {
      text: "Supports maintaining or further reducing tax rates",
      value: 70,
    },
    Immigration: {
      text: "Advocates for stricter border control and limiting immigration",
      value: 80,
    },
    "Voting Rights": {
      text: "Advocates for stricter voting laws, claims of election fraud",
      value: 80,
    },
    "Economy & Jobs": {
      text: "Emphasizes deregulation, tax cuts, and trade deal renegotiation",
      value: 70,
    },
    "AI Regulation": {
      text: "Generally favors less regulation on new technologies",
      value: 60,
    },
    "Foreign Policy": {
      text: 'Advocates for "America First" approach and bilateral negotiations',
      value: 80,
    },
    Education: {
      text: "Advocates for school choice and reduced federal involvement",
      value: 70,
    },
    "Racial Equality": {
      text: "Emphasizes 'law and order', critical of some racial equity initiatives",
      value: 60,
    },
    "Affirmative Action": {
      text: "Opposed affirmative action policies, supported race-neutral approaches",
      value: 70,
    },
    "Ukraine Conflict": {
      text: "Skeptical of extensive aid to Ukraine. Suggests he could quickly negotiate an end to the conflict if re-elected.",
      value: 60,
    },
    "China-Taiwan Relations": {
      text: "Supports Taiwan but has suggested rethinking One China policy. Emphasizes economic pressure on China.",
      value: 50,
    },
    "Russia Relations": {
      text: "Advocates for improved relations with Russia. Criticizes NATO expansion and suggests he can negotiate with Putin.",
      value: 80,
    },
    "China Policy": {
      text: "Advocates for tough stance on China, particularly on trade. Supports tariffs and economic decoupling.",
      value: 70,
    },
    "Israel-Palestine Conflict": {
      text: "Strongly pro-Israel stance. Moved U.S. embassy to Jerusalem and brokered Abraham Accords. Skeptical of two-state solution.",
      value: 80,
    },
    "LGBTQ+ Rights": {
      text: "Opposed Equality Act, implemented transgender military ban, but supported some LGBTQ initiatives.",
      value: 60,
    },
    "Religious Freedom": {
      text: "Emphasized protecting religious liberty, especially for conservative Christians.",
      value: 80,
    },
    "Women's Rights": {
      text: "Appointed pro-life judges, supports some women's initiatives but opposes abortion rights.",
      value: 70,
    },
    "Election Integrity": {
      text: "Advocates for stricter voting laws, claims of widespread election fraud",
      value: 80,
    },
    "Critical Race Theory": {
      text: "Opposes CRT, issued executive order limiting diversity training in federal agencies",
      value: 80,
    },
    "Energy Policy": {
      text: "Advocates for fossil fuel industry, withdrew from Paris Agreement",
      value: 80,
    },
    "Tech Regulation": {
      text: "Advocates for Section 230 reform, claims tech companies censor conservative voices",
      value: 70,
    },
    Homelessness: {
      text: "Advocated for local control and law enforcement approaches to address homelessness",
      value: 50,
    },
    "Stock Market": {
      text: "Advocates for deregulation and lower taxes to boost market performance",
      value: 80,
    },
    "Gas Prices": {
      text: "Advocates for increased domestic oil production to lower prices",
      value: 70,
    },
    "Energy Independence": {
      text: "Emphasizes fossil fuel production for energy independence",
      value: 80,
    },
    "Veteran Affairs": {
      text: "Implemented VA Choice program, focused on accountability in VA",
      value: 50,
    },
    "Use of Force View": {
      text: "Strongly supports law enforcement, advocates for 'law and order' approach",
      value: 80,
    },
    "Public Schools": {
      text: "Advocates for school choice, including charter schools and vouchers",
      value: 70,
    },
  },
  "J.D. Vance": {
    _color: "red",
    "Gun Control": {
      text: "Strongly supports Second Amendment rights, opposes most gun control measures",
      value: 85,
    },
    Healthcare: {
      text: "Advocates for market-based solutions and reducing healthcare costs",
      value: 70,
    },
    "Income Inequality": {
      text: "Emphasizes job creation and economic growth over wealth redistribution",
      value: 65,
    },
    "Student Debt Relief": {
      text: "Generally opposes broad student loan forgiveness, focuses on education reform",
      value: 60,
    },
    "Criminal Justice Reform": {
      text: "Supports some reform measures, but emphasizes law and order",
      value: 40,
    },
    "Climate Change": {
      text: "Skeptical of aggressive climate policies, supports U.S. energy independence",
      value: 75,
    },
    Taxes: {
      text: "Advocates for lower taxes, especially for middle-class families and businesses",
      value: 80,
    },
    Immigration: {
      text: "Supports strict border control and limiting both legal and illegal immigration",
      value: 90,
    },
    "Voting Rights": {
      text: "Supports voter ID laws and opposes expansion of mail-in voting",
      value: 75,
    },
    "Economy & Jobs": {
      text: "Emphasizes bringing manufacturing jobs back to the U.S. and economic nationalism",
      value: 70,
    },
    "AI Regulation": {
      text: "Supports some regulation of AI to protect American interests and jobs",
      value: 60,
    },
    "Foreign Policy": {
      text: "Advocates for an 'America First' approach, skeptical of international engagements",
      value: 85,
    },
    Education: {
      text: "Supports school choice and opposes what he sees as left-wing ideology in education",
      value: 80,
    },
    "Racial Equality": {
      text: "Emphasizes economic opportunity over race-specific policies",
      value: 65,
    },
    "Affirmative Action": {
      text: "Opposes affirmative action policies in education and employment",
      value: 85,
    },
    "Ukraine Conflict": {
      text: "Initially skeptical of U.S. involvement, but has supported some aid to Ukraine",
      value: 60,
    },
    "China-Taiwan Relations": {
      text: "Supports a tough stance on China and protection of Taiwan",
      value: 75,
    },
    "Russia Relations": {
      text: "Advocates for a pragmatic approach, neither overly hawkish nor conciliatory",
      value: 50,
    },
    "China Policy": {
      text: "Supports aggressive measures to counter China's economic and geopolitical influence",
      value: 85,
    },
    "Israel-Palestine Conflict": {
      text: "Strongly pro-Israel stance",
      value: 80,
    },
    "LGBTQ+ Rights": {
      text: "Generally opposes expansion of LGBTQ+ rights, supports traditional values",
      value: 75,
    },
    "Religious Freedom": {
      text: "Strongly supports religious freedom, especially for Christians",
      value: 85,
    },
    "Women's Rights": {
      text: "Pro-life stance, opposes federal funding for abortion",
      value: 80,
    },
    "Election Integrity": {
      text: "Supports stricter voting laws and has expressed concerns about election fraud",
      value: 85,
    },
    "Critical Race Theory": {
      text: "Strongly opposes teaching of critical race theory in schools",
      value: 90,
    },
    "Energy Policy": {
      text: "Supports fossil fuel production and energy independence",
      value: 85,
    },
    "Tech Regulation": {
      text: "Advocates for breaking up big tech companies and reducing their influence",
      value: 70,
    },
    Homelessness: {
      text: "Emphasizes personal responsibility and mental health treatment over housing-first approaches",
      value: 65,
    },
    "Stock Market": {
      text: "Supports policies to benefit American investors and reduce foreign influence",
      value: 70,
    },
    "Gas Prices": {
      text: "Advocates for increased domestic oil production to lower gas prices",
      value: 80,
    },
    "Energy Independence": {
      text: "Strongly supports policies to achieve U.S. energy independence",
      value: 90,
    },
    "Veteran Affairs": {
      text: "Supports increased funding and improved services for veterans",
      value: 75,
    },
    "Use of Force View": {
      text: "Supports a strong military but is skeptical of foreign interventions",
      value: 65,
    },
    "Public Schools": {
      text: "Advocates for school choice and increased local control of education",
      value: 80,
    },
  },
  "Robert F. Kennedy Jr.": {
    _color: "purple",
    "Gun Control": {
      text: "Supports 2nd Amendment rights with some regulations",
      value: 20,
    },

    Healthcare: {
      text: "Advocates for universal healthcare system",
      value: -80,
    },

    "Income Inequality": {
      text: "Advocates for addressing wealth disparity, criticizes corporate influence",
      value: -80,
    },

    "Student Debt Relief": {
      text: "Supports significant student debt relief and education reform",
      value: -80,
    },

    "Criminal Justice Reform": {
      text: "Advocates for major criminal justice system overhaul and drug policy reform",
      value: -80,
    },

    "Climate Change": {
      text: "Advocates for aggressive climate action and clean energy",
      value: -90,
    },

    Taxes: {
      text: "Advocates for progressive taxation and closing loopholes",
      value: -70,
    },

    Immigration: {
      text: "Supports immigration reform with focus on worker protection",
      value: -30,
    },

    "Voting Rights": {
      text: "Supports election integrity measures, open to ID requirements",
      value: 20,
    },

    "Economy & Jobs": {
      text: "Advocates for breaking up monopolies and supporting small businesses",
      value: -40,
    },

    "AI Regulation": {
      text: "Expresses concerns about AI's impact on privacy and jobs",
      value: -30,
    },

    "Foreign Policy": {
      text: "Promotes non-interventionist policy and diplomacy",
      value: -20,
    },

    Education: {
      text: "Emphasizes education reform and addressing student debt",
      value: -40,
    },

    "Racial Equality": {
      text: "Advocates for addressing systemic racism while criticizing some current approaches",
      value: -50,
    },

    "Affirmative Action": {
      text: "Supports some affirmative action measures while advocating for broader reforms",
      value: -40,
    },

    "Ukraine Conflict": {
      text: "Advocates for de-escalation and negotiation. Critical of extensive U.S. involvement and aid to Ukraine.",
      value: 20,
    },

    "China-Taiwan Relations": {
      text: "Advocates for diplomatic solutions and reducing U.S. military presence in Asia. Supports Taiwan's self-determination.",
      value: -30,
    },

    "Russia Relations": {
      text: "Calls for diplomacy and de-escalation of tensions with Russia. Criticizes current U.S. approach as overly hostile.",
      value: 20,
    },

    "China Policy": {
      text: "Emphasizes cooperation on global issues like climate change. Critical of aggressive U.S. posture in Asia.",
      value: -40,
    },

    "Israel-Palestine Conflict": {
      text: "Calls for balanced approach. Supports Israel's right to exist securely while advocating for Palestinian human rights and statehood.",
      value: -20,
    },

    "LGBTQ+ Rights": {
      text: "Supports LGBTQ+ rights, critical of some transgender policies in sports and youth medical care.",
      value: -40,
    },

    "Religious Freedom": {
      text: "Supports religious freedom, advocates for separation of church and state.",
      value: -30,
    },

    "Women's Rights": {
      text: "Supports women's rights, including reproductive rights, with some nuanced positions.",
      value: -50,
    },

    "Election Integrity": {
      text: "Supports some election integrity measures, concerned about electronic voting systems",
      value: 20,
    },

    "Critical Race Theory": {
      text: "Has criticized some aspects of CRT but supports addressing systemic racism",
      value: -20,
    },

    "Energy Policy": {
      text: "Supports renewable energy but also criticizes some green energy initiatives",
      value: -40,
    },

    "Tech Regulation": {
      text: "Criticizes big tech censorship, advocates for digital privacy rights",
      value: 20,
    },

    Homelessness: {
      text: "Supports housing-first approach and addressing root causes like mental health and addiction",
      value: -50,
    },

    "Stock Market": {
      text: "Criticizes market manipulation, supports some increased regulations",
      value: -30,
    },

    "Gas Prices": {
      text: "Supports both renewable energy and domestic oil production to stabilize prices",
      value: 0,
    },

    "Energy Independence": {
      text: "Supports mix of renewable and traditional energy sources for independence",
      value: -20,
    },

    "Veteran Affairs": {
      text: "Advocates for improved healthcare and benefits for veterans",
      value: -30,
    },

    "Use of Force View": {
      text: "Supports police reform and accountability, opposes militarization of police",
      value: -50,
    },

    "Public Schools": {
      text: "Supports public school funding, critical of standardized testing",
      value: -40,
    },
  },
  "Project 2025": {
    _color: "red",
    "Gun Control": {
      text: "The new administration should safeguard the Second Amendment and ensure that any proposed gun control measures are not infringing upon the right to bear arms.",
      value: 90,
    },

    Healthcare: {
      text: "Reform healthcare by emphasizing private insurance, reducing federal involvement, and promoting market-driven solutions.",
      value: 80,
    },

    "Income Inequality": {
      text: "Address income inequality by fostering economic growth through tax reforms, deregulation, and supporting small businesses.",
      value: 70,
    },

    "Student Debt Relief": {
      text: "Eliminate federal student loan forgiveness programs, privatize lending, and reform loan structures to ensure accountability and reduce taxpayer burden.",
      value: 70,
    },

    "Criminal Justice Reform": {
      text: "Focus on law and order by supporting police, enforcing existing laws, and eliminating DEI initiatives within the justice system.",
      value: 80,
    },

    "Climate Change": {
      text: "Cease the war on fossil fuels, support responsible energy management, and remove radical climate policies from foreign aid programs.",
      value: 80,
    },

    Taxes: {
      text: "Simplify the tax code, reduce corporate and individual tax rates, and eliminate recent tax increases from the Inflation Reduction Act.",
      value: 90,
    },

    Immigration: {
      text: "Enforce existing immigration laws, strengthen border security, and streamline legal immigration processes.",
      value: 90,
    },

    "Voting Rights": {
      text: "Ensure election integrity by requiring voter ID, eliminating ballot harvesting, and enforcing strict measures against voter fraud.",
      value: 90,
    },

    "Economy & Jobs": {
      text: "Promote economic growth through deregulation, tax cuts, and support for manufacturing and small businesses.",
      value: 90,
    },

    "AI Regulation": {
      text: "Support AI development with minimal regulation, emphasizing innovation and free-market solutions.",
      value: 80,
    },

    "Foreign Policy": {
      text: "Adopt a foreign policy that prioritizes American interests, focuses on combating China's influence, and reassesses involvement in conflicts like Ukraine.",
      value: 80,
    },

    Education: {
      text: "Promote school choice, reduce federal involvement in education, and support alternative education models like charter schools and vouchers.",
      value: 90,
    },

    "Racial Equality": {
      text: "Eliminate DEI initiatives and racial classifications in government programs, ensuring equal treatment under the law.",
      value: 70,
    },

    "Affirmative Action": {
      text: "Prohibit the use of racial preferences in education and employment, ensuring merit-based selection.",
      value: 80,
    },

    "Ukraine Conflict": {
      text: "Reassess U.S. involvement in Ukraine, focusing on diplomatic solutions and limiting military aid.",
      value: 70,
    },

    "China-Taiwan Relations": {
      text: "Support Taiwan's self-determination while maintaining strategic ambiguity and reducing U.S. military presence in Asia.",
      value: 60,
    },

    "Russia Relations": {
      text: "Pursue diplomatic solutions with Russia, focusing on de-escalation and avoiding unnecessary conflicts.",
      value: 70,
    },

    "China Policy": {
      text: "Adopt a competitive approach to China, emphasizing economic initiatives and alliances in Asia.",
      value: 80,
    },

    "Israel-Palestine Conflict": {
      text: "Support Israel while advocating for a balanced approach to Palestinian rights and statehood.",
      value: 70,
    },

    "LGBTQ+ Rights": {
      text: "Oppose radical gender ideology in schools and government, protecting traditional values and religious freedoms.",
      value: 80,
    },

    "Religious Freedom": {
      text: "Protect religious freedoms, ensuring that federal policies do not infringe upon the rights of religious individuals and organizations.",
      value: 90,
    },

    "Women's Rights": {
      text: "Focus on protecting women's rights within the framework of traditional values, opposing radical gender policies.",
      value: 70,
    },

    "Election Integrity": {
      text: "Ensure fair and secure elections by implementing strict voter ID laws and eliminating practices that could lead to fraud.",
      value: 90,
    },

    "Critical Race Theory": {
      text: "Ban CRT training in federal agencies and ensure that educational institutions do not promote divisive racial ideologies.",
      value: 90,
    },

    "Energy Policy": {
      text: "Promote energy independence through the development of all energy sources, including fossil fuels and nuclear energy.",
      value: 90,
    },

    "Tech Regulation": {
      text: "Encourage innovation in the tech sector by reducing regulatory burdens and promoting free-market solutions.",
      value: 80,
    },

    Homelessness: {
      text: "Address homelessness by focusing on mental health and addiction treatment, rather than solely providing housing.",
      value: 70,
    },

    "Stock Market": {
      text: "Promote market stability and growth through deregulation and tax reforms that encourage investment.",
      value: 80,
    },

    "Gas Prices": {
      text: "Increase domestic oil production to lower gas prices and ensure energy affordability.",
      value: 80,
    },

    "Energy Independence": {
      text: "Achieve energy independence by maximizing the use of domestic energy resources, including fossil fuels.",
      value: 90,
    },

    "Veteran Affairs": {
      text: "Improve VA services by reducing bureaucratic inefficiencies and ensuring veterans receive timely and high-quality care.",
      value: 70,
    },

    "Use of Force View": {
      text: "Support law enforcement and ensure that police have the necessary resources to maintain public safety.",
      value: 80,
    },

    "Public Schools": {
      text: "Enhance public education by promoting school choice and reducing federal regulations.",
      value: 80,
    },
  },
  "GOP Platform": {
    _color: "red",
    "Gun Control": {
      text: "Defend our Constitution, our Bill of Rights, and our fundamental freedoms, including the right to keep and bear arms",
      value: 90,
    },

    Healthcare: {
      text: "Protect Medicare, and ensure Seniors receive the care they need without being burdened by excessive costs",
      value: 60,
    },

    "Income Inequality": {
      text: "Not Addressed",
      value: null,
    },

    "Student Debt Relief": {
      text: "Support the creation of additional, drastically more affordable alternatives to a traditional four-year College degree",
      value: 60,
    },

    "Criminal Justice Reform": {
      text: "Restore safety in neighborhoods by replenishing Police Departments, restoring Common Sense Policing, and protecting Officers from frivolous lawsuits",
      value: 80,
    },

    "Climate Change": {
      text: "Unleash Energy Production from all sources, including nuclear, to immediately slash Inflation and power American homes, cars, and factories with reliable, abundant, and affordable Energy",
      value: 80,
    },

    Taxes: {
      text: "Make Trump Tax Cuts permanent, eliminate taxes on tips, and pursue additional tax cuts",
      value: 90,
    },

    Immigration: {
      text: "Seal the Border, stop the Migrant Invasion, complete the Border Wall, and carry out the largest deportation operation in American history",
      value: 90,
    },

    "Voting Rights": {
      text: "Implement measures to secure our Elections, including Voter ID, highly sophisticated paper ballots, proof of Citizenship, and same day Voting",
      value: 90,
    },

    "Economy & Jobs": {
      text: "Build the Greatest Economy in History, bring back Manufacturing Jobs, and end Inflation",
      value: 90,
    },

    "AI Regulation": {
      text: "Repeal Joe Biden's dangerous Executive Order that hinders AI Innovation, and support AI Development rooted in Free Speech and Human Flourishing",
      value: 80,
    },

    "Foreign Policy": {
      text: "Return to Peace through Strength, rebuilding our Military and Alliances, countering China, defeating terrorism, and promoting American Values",
      value: 80,
    },

    Education: {
      text: "Support Universal School Choice, expand 529 Education Savings Accounts, and support Homeschooling Families equally",
      value: 90,
    },

    "Racial Equality": {
      text: "Enforce our Civil Rights Laws to stop schools from discriminating on the basis of Race",
      value: 70,
    },

    "Affirmative Action": {
      text: "Not Addressed",
      value: null,
    },

    "Ukraine Conflict": {
      text: "Prevent World War Three, restore Peace in Europe and in the Middle East",
      value: 60,
    },

    "China-Taiwan Relations": {
      text: "Not Addressed",
      value: null,
    },

    "Russia Relations": {
      text: "Not Addressed",
      value: null,
    },

    "China Policy": {
      text: "Revoke China's Most Favored Nation status, phase out imports of essential goods, and stop China from buying American Real Estate and Industries",
      value: 90,
    },

    "Israel-Palestine Conflict": {
      text: "Stand with Israel, and seek peace in the Middle East",
      value: 80,
    },

    "LGBTQ+ Rights": {
      text: "Keep men out of women's sports, ban Taxpayer funding for sex change surgeries, and stop Taxpayer-funded Schools from promoting gender transition",
      value: 90,
    },

    "Religious Freedom": {
      text: "Defend our Constitution, our Bill of Rights, and our fundamental freedoms, including freedom of religion. Support a new Federal Task Force on Fighting Anti-Christian Bias that will investigate all forms of illegal discrimination, harassment, and persecution against Christians in America.",
      value: 90,
    },

    "Women's Rights": {
      text: "Protect and defend a vote of the People, from within the States, on the Issue of Life. Oppose Late Term Abortion, while supporting mothers and policies that advance Prenatal Care, access to Birth Control, and IVF (fertility treatments)",
      value: 70,
    },

    "Election Integrity": {
      text: "Implement measures to secure our Elections, including Voter ID, highly sophisticated paper ballots, proof of Citizenship, and same day Voting",
      value: 90,
    },

    "Critical Race Theory": {
      text: "Defund schools that engage in inappropriate political indoctrination of our children using Federal Taxpayer Dollars",
      value: 90,
    },

    "Energy Policy": {
      text: "Unleash American Energy, become Energy Independent and Dominant again, and harness potential to power our future",
      value: 90,
    },

    "Tech Regulation": {
      text: "Ban the Federal Government from colluding with anyone to censor Lawful Speech, defund institutions engaged in censorship, and hold accountable all bureaucrats involved with illegal censoring",
      value: 80,
    },

    Homelessness: {
      text: "Compassionately address homelessness to restore order to our streets",
      value: 70,
    },

    "Stock Market": {
      text: "Keep the U.S. Dollar as the World's Reserve Currency",
      value: 80,
    },

    "Gas Prices": {
      text: "Not Addressed",
      value: null,
    },

    "Energy Independence": {
      text: "Unleash American Energy, become Energy Independent and Dominant again",
      value: 90,
    },

    "Veteran Affairs": {
      text: "End luxury housing and Taxpayer benefits for Illegal Immigrants and use those savings to shelter and treat homeless Veterans",
      value: 80,
    },

    "Use of Force View": {
      text: "Not Addressed",
      value: null,
    },

    "Public Schools": {
      text: "Support Universal School Choice, expand 529 Education Savings Accounts, and support Homeschooling Families equally",
      value: 90,
    },
  },
};

const ProgressBar = ({ value }) => {
  const progressWidth = Math.abs(value);
  const isProgressive = value < 0;
  const barColor = isProgressive ? "bg-blue-500" : "bg-red-500";

  return (
    <div className="w-full h-2.5 mt-1 flex items-center">
      <div className="w-1/2 bg-gray-200 h-full flex justify-end">
        {isProgressive && (
          <div
            className={`h-full ${barColor}`}
            style={{ width: `${progressWidth}%` }}
          ></div>
        )}
      </div>
      <div className="w-1 h-4 bg-gray-400"></div>
      <div className="w-1/2 bg-gray-200 h-full flex justify-start">
        {!isProgressive && (
          <div
            className={`h-full ${barColor}`}
            style={{ width: `${progressWidth}%` }}
          ></div>
        )}
      </div>
    </div>
  );
};

const PolicyCard = ({
  issue,
  isAllFlipped,
  sortKey,
  searchTerm,
  filteredCandidates,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const dragStartPos = useRef({ x: 0, y: 0 });
  const timeoutRef = useRef(null);

  useEffect(() => {
    setIsOpen(isAllFlipped);
  }, [isAllFlipped]);

  useEffect(() => {
    setIsOpen(false);
  }, [sortKey]);

  const matchesSearch = () => {
    if (!searchTerm) return true;
    const lowerSearchTerm = searchTerm.toLowerCase();
    return (
      issue.name.toLowerCase().includes(lowerSearchTerm) ||
      filteredCandidates.some((candidate) =>
        candidates[candidate][issue.name].text
          .toLowerCase()
          .includes(lowerSearchTerm)
      )
    );
  };

  if (!matchesSearch()) return null;

  const getAverageColor = () => {
    const totalValue = filteredCandidates.reduce(
      (sum, candidate) => sum + candidates[candidate][issue.name].value,
      0
    );
    const averageValue = totalValue / filteredCandidates.length;

    if (averageValue > 1) return "bg-red-500";
    if (averageValue < -1) return "bg-blue-500";
    return "bg-purple-500";
  };

  const colorClass = getAverageColor();

  const handleMouseDown = (e) => {
    dragStartPos.current = { x: e.clientX, y: e.clientY };
    timeoutRef.current = setTimeout(() => setIsDragging(true), 200);
  };

  const handleMouseMove = (e) => {
    if (timeoutRef.current) {
      const diffX = Math.abs(e.clientX - dragStartPos.current.x);
      const diffY = Math.abs(e.clientY - dragStartPos.current.y);
      if (diffX > 5 || diffY > 5) {
        clearTimeout(timeoutRef.current);
        setIsDragging(true);
      }
    }
  };

  const handleMouseUp = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsDragging(false);
  };

  // const toggleOpen = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (!isAllFlipped) {
  //     setIsOpen(!isOpen);
  //   }
  // };

  const toggleOpen = (e) => {
    if (!isDragging && !window.getSelection().toString()) {
      e.preventDefault();
      e.stopPropagation();
      if (!isAllFlipped) {
        setIsOpen(!isOpen);
      }
    }
  };

  return (
    <div
      className="relative w-full sm:w-80 h-auto min-h-[32rem] m-4 rounded-lg shadow-lg overflow-hidden transition-all duration-300 transform hover:scale-105 border-2"
      onClick={toggleOpen}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div
        className={`absolute inset-0 bg-white flex flex-col items-center justify-center transition-all duration-300 ${
          isOpen ? "opacity-0 pointer-events-none" : "opacity-100"
        }`}
      >
        <issue.icon
          size={48}
          className={`mb-2 ${colorClass} text-white rounded-full p-2`}
        />
        <h3 className="text-lg font-semibold text-gray-800">{issue.name}</h3>
      </div>
      <div
        className={`absolute inset-0 bg-white transition-all duration-300 ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div className="h-full overflow-auto p-4">
          <h3 className="text-lg font-semibold mb-2">{issue.name}</h3>
          {filteredCandidates.map((candidate) => (
            <div key={candidate} className="mb-4">
              <p className="text-sm mb-1">
                <span className="font-semibold">{candidate}:</span>{" "}
                {candidates[candidate][issue.name].text}
              </p>
              <ProgressBar value={candidates[candidate][issue.name].value} />
            </div>
          ))}
          <div className="flex justify-between text-xs text-gray-500 mt-2">
            <span>Progressive</span>
            <span>Centrist</span>
            <span>Conservative</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const CandidateComparison = () => {
  const [allFlipped, setAllFlipped] = useState(false);
  const [sortOption, setSortOption] = useState("unsorted");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCandidates, setSelectedCandidates] = useState(
    Object.keys(candidates).reduce((acc, candidate) => {
      acc[candidate] = true;
      return acc;
    }, {})
  );

  const handleCandidateToggle = (candidate) => {
    setSelectedCandidates((prev) => ({
      ...prev,
      [candidate]: !prev[candidate],
    }));
  };

  const filteredCandidates = Object.keys(candidates).filter(
    (candidate) => selectedCandidates[candidate]
  );

  const toggleAllCards = () => {
    setAllFlipped(!allFlipped);
  };

  const handleSort = (option) => {
    setSortOption(option);
    if (!allFlipped) {
      setAllFlipped(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const sortIssues = (issues) => {
    switch (sortOption) {
      case "conservative":
        return [...issues].sort(
          (a, b) => getAverageStance(b.name) - getAverageStance(a.name)
        );
      case "progressive":
        return [...issues].sort(
          (a, b) => getAverageStance(a.name) - getAverageStance(b.name)
        );
      case "agreed":
        return [...issues].sort(
          (a, b) => getStanceDeviation(a.name) - getStanceDeviation(b.name)
        );
      case "project2025":
        return [...issues].sort(
          (a, b) =>
            Math.abs(candidates["Project 2025"][b.name].value) -
            Math.abs(candidates["Project 2025"][a.name].value)
        );
      default:
        return issues;
    }
  };

  const getAverageStance = (issueName) => {
    const totalValue = filteredCandidates.reduce(
      (sum, candidate) => sum + candidates[candidate][issueName].value,
      0
    );
    return totalValue / filteredCandidates.length;
  };

  const getStanceDeviation = (issueName) => {
    const values = filteredCandidates.map(
      (candidate) => candidates[candidate][issueName].value
    );
    const avg = values.reduce((sum, val) => sum + val, 0) / values.length;
    const squareDiffs = values.map((value) => (value - avg) ** 2);
    return Math.sqrt(
      squareDiffs.reduce((sum, sqDiff) => sum + sqDiff, 0) / values.length
    );
  };

  const sortedIssues = sortIssues(issues);

  const SortButton = ({ option, label }) => (
    <button
      onClick={() => handleSort(option)}
      className={`px-3 py-1 rounded transition-colors ${
        sortOption === option
          ? "bg-blue-500 text-white"
          : "bg-gray-200 text-gray-800 hover:bg-gray-300"
      }`}
    >
      {label}
    </button>
  );

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8 flex flex-col">
      <div className="max-w-7xl mx-auto flex-grow">
        <h1 className="text-3xl font-extrabold text-gray-900 text-center mb-10">
          2024 Presidential Candidate and Policy Comparison
        </h1>
        <p className="text-center text-gray-600 mb-4">
          Tap or hover over each card to see candidate positions, Project 2025
          perspectives, GOP Platform, and their placement on a
          progressive-conservative scale.
        </p>
        <div className="flex justify-center space-x-2 mb-4 flex-wrap">
          <SortButton option="unsorted" label="Unsorted" />
          <SortButton option="conservative" label="Conservative Leading" />
          <SortButton option="progressive" label="Progressive Leading" />
          <SortButton option="agreed" label="Mostly Agreed" />
          <SortButton option="project2025" label="Project 2025 Ranking" />
        </div>
        <div className="flex justify-center items-center space-x-4 mb-8 flex-wrap">
          <button
            onClick={toggleAllCards}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {allFlipped ? "Close All" : "Flip All"}
          </button>
          <div className="relative">
            <input
              type="text"
              placeholder="Search policies..."
              value={searchTerm}
              onChange={handleSearch}
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <Search
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              size={20}
            />
          </div>
        </div>
        <div className="flex justify-center space-x-2 mb-4 flex-wrap">
          {Object.keys(candidates).map((candidate) => (
            <label
              key={candidate}
              className="inline-flex items-center mr-4 mb-2"
            >
              <input
                type="checkbox"
                checked={selectedCandidates[candidate]}
                onChange={() => handleCandidateToggle(candidate)}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span className={`ml-2`}>{candidate}</span>
            </label>
          ))}
        </div>
        <div className="flex flex-wrap justify-center">
          {sortIssues(issues).map((issue) => (
            <PolicyCard
              key={issue.name}
              issue={issue}
              isAllFlipped={allFlipped}
              sortKey={sortOption}
              searchTerm={searchTerm}
              filteredCandidates={filteredCandidates}
            />
          ))}
        </div>
      </div>
      <footer className="w-full bg-gray-200 py-4 mt-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <p className="text-center text-gray-600 text-sm">
            Disclaimer: This content is AI-generated by Claude AI / ChatGPT(4o)
            for informational purposes only. Positions and policies represented
            may not be fully accurate or up-to-date. Please verify information
            with official sources before making decisions.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default CandidateComparison;
